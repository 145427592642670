// Generated by purs version 0.13.6
"use strict";
var Data_Symbol = require("../Data.Symbol/index.js");
var Literals_Int = require("../Literals.Int/index.js");
var Literals_String = require("../Literals.String/index.js");
var warning = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "warning";
}));
var wait = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "wait";
}));
var vertical = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "vertical";
}));
var validating = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "validating";
}));
var two = Literals_Int.intLit(new Data_Symbol.IsSymbol(function () {
    return "2";
}))();
var three = Literals_Int.intLit(new Data_Symbol.IsSymbol(function () {
    return "3";
}))();
var tags = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "tags";
}));
var success = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "success";
}));
var small = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "small";
}));
var round = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "round";
}));
var right = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "right";
}));
var process = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "process";
}));
var primary = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "primary";
}));
var one = Literals_Int.intLit(new Data_Symbol.IsSymbol(function () {
    return "1";
}))();
var navigation = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "navigation";
}));
var multiple = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "multiple";
}));
var middle = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "middle";
}));
var link = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "link";
}));
var left = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "left";
}));
var large = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "large";
}));
var inline = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "inline";
}));
var horizontal = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "horizontal";
}));
var ghost = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "ghost";
}));
var four = Literals_Int.intLit(new Data_Symbol.IsSymbol(function () {
    return "4";
}))();
var finish = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "finish";
}));
var error = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "error";
}));
var $$default = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "default";
}));
var dashed = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "dashed";
}));
var danger = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "danger";
}));
var circle = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "circle";
}));
var center = Literals_String.stringLit(new Data_Symbol.IsSymbol(function () {
    return "center";
}));
module.exports = {
    circle: circle,
    round: round,
    small: small,
    middle: middle,
    large: large,
    primary: primary,
    ghost: ghost,
    dashed: dashed,
    danger: danger,
    link: link,
    horizontal: horizontal,
    inline: inline,
    vertical: vertical,
    left: left,
    right: right,
    success: success,
    warning: warning,
    validating: validating,
    one: one,
    two: two,
    three: three,
    four: four,
    multiple: multiple,
    tags: tags,
    "default": $$default,
    navigation: navigation,
    wait: wait,
    process: process,
    finish: finish,
    error: error,
    center: center
};
